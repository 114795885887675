/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-fog2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 3a5 5 0 014.905 4.027A3 3 0 0113 13h-1.5a.5.5 0 000-1H1.05a3.5 3.5 0 01-.713-1H9.5a.5.5 0 000-1H.035a3.5 3.5 0 010-1H7.5a.5.5 0 000-1H.337a3.5 3.5 0 013.57-1.977A5 5 0 018.5 3"/>',
    },
});
